import React, { useContext } from "react";
import AnnotatedDataContext from "../../store/data-context";

const VideoList = (props) => {
  const dataContext = useContext(AnnotatedDataContext);
  const handleVideoSelect = (video) => {
    props.callBack(video);
  };
function handleLinkClick(){}
  return (
    <div className="vidlist">
      <h2>Videók</h2>
      <ul>
        {Object.values(dataContext.annotatedData).map((video) => (
          <li key={video.id} onClick={() => handleVideoSelect(video)}>
              <a
                className={video.title === props.videoData.title ? "selected" : ""}
                onClick={() => handleLinkClick(video.id)}
                style={{color: video.label !== null && video.title !== props.videoData.title ? "#0badda" : null}}
                >
                {video.title}
              </a>
          </li>
        ))}
      </ul>
      <h4>Vizsgált videó:<br/>{props.videoData.title}</h4>
    </div>
  );
};

export default VideoList;
