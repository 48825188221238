import { createContext, useState, useRef } from "react";
import { twentyAnnotatedData } from "../datastructures.js/TwentyAnnotatedData";

const AnnotatedDataContext = createContext({
  annotatedData: {},
  workingTimeFor20vids: {},
  mightLoadNewData: {},
  setMightLoadNewData: () => {},
  fillAnnotatedData: () => {},
  checkIfListCompleted: () => {},
  sendAnnotatedData: () => {},
  receivedPreAnnotatedData: () => {},
  stopper: () => {},
});

export function AnnotatedDataContextProvider(props) {
  const [annotatedData, setAnnotatedData] = useState(twentyAnnotatedData);
  const lastSelectionTimeRef = useRef(null); // Ref to store the timestamp of the last video selection
  const [mayLoadNewData, setMayLoadNewData] = useState(false);
  const timeSpent = useRef(0);

  function addAnnotatedData(videoData, newLabel) {

    setAnnotatedData((prevData) => ({
      ...prevData,
      [`video${videoData.id}`]: { ...videoData, label: newLabel },
    }));

    // Update the state with the new object
  }

  function measureTimeBetweenSelections(video) {
    let timeDifference = 0;
    // If lastSelectionTimeRef.current is null, it means it's the first video selection
    if (lastSelectionTimeRef.current === null) {
      lastSelectionTimeRef.current = new Date(); // Store the timestamp of the first video selection

    } else {
      const currentTime = new Date();
      timeDifference = (currentTime - lastSelectionTimeRef.current)/1000 + annotatedData[`video${video.id}`].time; // Calculate the time difference
      if(60 < timeDifference) timeDifference = 60;

      timeSpent.current += (timeDifference - annotatedData[`video${video.id}`].time);

      setAnnotatedData((prevData) => ({
        ...prevData,
        [`video${video.id}`]: { ...video, time: timeDifference },
      }));
      lastSelectionTimeRef.current = currentTime; // Update the last selection timestamp to the current time

    }
    return (timeDifference > 15);
  }

  function listCompleted() {
    let completed = true;
    for (let video of Object.values(annotatedData)) {
      if (video.label === null) {
        completed = false;
        break;
      }
    }
    return completed;
  }

  function transformDataForDjango(email, data) {
    // Initialize variables for summing duration and collecting src
    let totalDuration = 0;
  
    // Loop through each video in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const video = data[key];
        // Add the time attribute to totalDuration
        totalDuration += video.time;

      }
    }
    const roundedDuration = Math.ceil(timeSpent.current);

    // Create the new data structure
    
    const transformedData = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const { id, title, src, label, time } = data[key];
        const filename = src.substring(src.lastIndexOf('/') + 1);
        transformedData[key] = { id, title, src: filename, label, time };
      }
    }

    // Create the new data structure
    const newData = {
      username: email, // Replace "email" with actual email
      duration_seconds: roundedDuration,
      date: (new Date().getMonth()+1),
      ...transformedData,
    };
  
    return newData;
  }

  function transformData(email, data) {
    // Initialize variables for summing duration and collecting src
    let totalDuration = 0;
  
    // Loop through each video in the data object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const video = data[key];
        // Add the time attribute to totalDuration
        totalDuration += video.time;
      }
    }
    const roundedDuration = Math.ceil(timeSpent.current);

    // Create the new data structure
    const newData = {
      username: email,
      duration_seconds: roundedDuration,
      date: (new Date().getMonth()+1),
    };
    
    return newData;
  }

  function fillSrcFields(arrayOfSrc) {
    const updatedData = { ...twentyAnnotatedData }; // Make a copy of the original data
  
    // Iterate through the keys of the original data structure
    Object.keys(updatedData).forEach((key, index) => {
      if (index < arrayOfSrc.length) { // Check if there's a corresponding src in the array
        updatedData[key].src = process.env.REACT_APP_VIDSRC + arrayOfSrc[index].src; // Fill the src field with the corresponding value from the array
      }
    });

    return updatedData;
  }

  async function sendData(user) {

    const transformedData = transformData(user, annotatedData);
    
    const workTimeMessage = `;${transformedData.username} ${transformedData.duration_seconds} ${transformedData.date}`;

    //websocket
    const socket = new WebSocket(process.env.REACT_APP_WEBSPATH);
    let message= "";
    for(const key in annotatedData){
      if (annotatedData.hasOwnProperty(key)) {
        const { id, title, src, label, time } = annotatedData[key]
        message += `${user} ${src} ${label},`;
      }
    }
    message += workTimeMessage;
    socket.onopen = () => {
      socket.send(message);

    };
    socket.onclose = (event) => {

    };

    try {
      const transformedData = transformDataForDjango(user, annotatedData);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", process.env.REACT_APP_ITEMS);
      xhr.setRequestHeader("Content-Type", "application/json");

      // Create a Promise to wrap the XMLHttpRequest
      const requestPromise = new Promise((resolve, reject) => {
          xhr.onload = function() {
              if (xhr.status === 200) {
                   resolve(xhr.responseText);
              } else {
                  reject(new Error("Request failed with status: " + xhr.status));
              }
          };

          xhr.onerror = function() {
              reject(new Error("Request failed"));
          };
      });

      // Send the request
      xhr.send(JSON.stringify(transformedData));

      // Wait for the request to complete
      const response = await requestPromise;

    } catch (error) {

    }
  }

  async function receiveData() {
    const socket = new WebSocket(process.env.REACT_APP_WEBSPATH);

    const openSocket = () => {
      return new Promise((resolve, reject) => {
        socket.onopen = () => {
          socket.send("&GET_Videos");
          resolve();
        };
        socket.onerror = (error) => {
          reject(error);
        };
      });
    };

    const receiveMessage = () => {
      return new Promise((resolve) => {
        socket.onmessage = (event) => {
          const dataReceived = JSON.parse(event.data);
          const updatedData = fillSrcFields(JSON.parse(dataReceived));
          console.log(updatedData);
          setAnnotatedData(updatedData);
          timeSpent.current = 0;
          resolve();
        };
      });
    };

    const closeSocket = () => {
      return new Promise((resolve) => {
        socket.onclose = (event) => {
          resolve();
        };
      });
    };

    // Execute the promises in order
    openSocket()
      .then(receiveMessage)
      .then(closeSocket)
      .catch((error) => {
        console.error('WebSocket error:', error);
      });

    // Cleanup function to remove event listener and close the socket connection when component unmounts
    return () => {
      socket.close();
    };
  }

  function shouldILoadNewData(should){
    setMayLoadNewData(should);
  }

  const context = {
    annotatedData: annotatedData,
    mightLoadNewData: mayLoadNewData,
    setMightLoadNewData: shouldILoadNewData,
    fillAnnotatedData: addAnnotatedData,
    checkIfListCompleted: listCompleted,
    sendAnnotatedData: sendData,
    receivedPreAnnotatedData: receiveData,
    stopper: measureTimeBetweenSelections,
  };

  return (
    <AnnotatedDataContext.Provider value={context}>
      {props.children}
    </AnnotatedDataContext.Provider>
  );
}

export default AnnotatedDataContext;
