import React, { useContext } from "react";

import { Navigate } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";

export default function ProtectedRoute({ children }) {
  const {isAdmin } = useContext(AuthContext);

  return isAdmin ? children : <Navigate to="/" />;
}
