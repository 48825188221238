import { useNavigate } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import { AuthContext } from "../../store/AuthContext";
import { useContext, useEffect, useState } from "react";
import Footer from "../Footer/Footer";

function TaskDescription() {
  const authContext = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(authContext.isAdmin);
  const navigate = useNavigate();

  function handleClick(path) {
    navigate(path);
  }

  useEffect(() => {
    setIsAdmin(authContext.isAdmin);
  },[authContext.isAdmin]);

  return (
    <div className="page-container">
      <div className="content-wrap">
        <div style={{overflow: "visible", fontSize: '15px'}}>
          <NavBar />
          <div style={{ maxWidth: "700px", margin: "0 auto" }}>
            <br />
            <h3 style={{ maxWidth: "700px", margin: "0 auto" }}>
              FIGYELEM, az alábbiak hasznosak, kérjük <u>elolvasni</u> és <u>megérteni</u>:
            </h3>
            <br />
            <p><b><u>A feladat:</u></b></p>
            <p>
            A feldolgozandó videók koraszülöttek napi ápolási és nyugalmi időszakait tartalmazzák. A feladat eredményeként a babák kezelését, nyugalmát felügyelő AI kamerák
            fognak születni, ezért fontos, hogy alapos tagek szülessenek.</p>
            <p>
            <u>Nem jó a kép</u>: Túl sötét, túl világos vagy zajos a kép ahhoz, hogy meg lehessen állapítani a baba viselkedési állapotát. Előfordulhat más jellegű képhiba is (pl. hirtelen elmegy a kép vagy színes négyzetek jelennek meg malfunkció miatt).
            </p>
            <p>
            <u>Nincs bent a baba</u>: Az inkubátor üres - általában kenguruztatás zajlik ilyenkor a szülőkkel.
            </p>
            <p>
            <u>Invazív kezelés</u>: Definíciója: "A beteg testébe bőrön, nyálkahártyán, vagy testnyíláson keresztül behatoló fizikai behatolás." A koraszülöttek esetében a következő beavatkozásokat mentjük el invazív kezelésként, beavatkozásként: gyomorszonda lehelyezése; sarokszúrás; vérvétel más területről; injekció beadása; beöntés; légzéstámogatásra helyezés; kanül/branül behelyezése. Előfordulhatnak más invazív beavatkozások is, de ezek a leggyakoribbak és a legvalószínűbbek, hogy találkozunk velük az annotálás során.
            </p>
            <p>
            <u>Noninvazív kezelés</u>: Etető "kémény" (másnéven etető fecskendő) töltése; simogatás; pelenkacsere; ruhacsere; pozicionálás, rendezgetés; képdiagnosztikai eljárás (ultrahang vagy röntgen); szájüregi folyadék leszívása; orrlégző cseréje; kékfénykezelés (ezutóbbinál gyakran rossz a képminőség, mert az erős kék fényt nem tudja jól rögzíteni a kamera).
            </p>
            <p>
            <u>Nem látszik jól/teljesen</u>: Nem a képminőség, hanem a kamera rossz pozicionálása miatt, vagy a babára tett szemellenző, takaró miatt nem látni az összes végtagot, így a mozgásukat sem, vagy a baba szemét, hogy megállapítsuk, ébren van-e vagy sem
            </p>
            <br />
            <p>
              A részletes <b>tutorial</b> a kategóriákról: <a href="https://youtu.be/KWey5x61wD8" target="_blank">YouTube videó</a><br/>
              <a href="https://drive.google.com/drive/folders/1gFQpuO14UPXvYREwJ03N50ReGyC0RrpH?usp=sharing" target="_blank">Kiegészítő <b>leírás</b></a> az alvási kategóriákról.
              </p> 
              <br />
              <p><b><u>A felvételek:</u></b></p>
              <p style={{textIndent: "40px"}}>
              A felvételek anonimak, nem köthetők konkrét személyekhez. A felvételek készítése etikai engedéllyel történt, azok tartalma, képei, részletei <b>szigorúan bizalmasak
              és nem megoszthatóak harmadik személlyel</b>. A felvételek törlésre kerülnek a kutatási időszak végével. A hangsávot szándékosan eltávolítottuk a felvételekről, mert
              az ápolók személyes jogait sértenék azok megosztása.</p>
              <p style={{textIndent: "40px"}}>
              A kezelések <b>egyeseknek felkavarónak tűnnek</b>, azonban minden baba jól van és a látottak része az újszülöttklinikák napi rutinjához szükségesek.</p>
              <p style={{textIndent: "40px"}}>
              A felvételek vegyesen tartalmaznak nappali és éjszakai időszakot, továbbá olyan szakaszt is, amikor a kép kiértékelhetetlenül sötét. Lesznek nyugodt és síró babát
              tartalmazó részek, ápolási időszakok vagy üres inkubátoros periódusok. Ezeknek megfelelően szükséges a megfelelő kategóriát kiválasztani.</p>
              <br/>
              <p><b><u>Az annotáló felület:</u></b></p>
              <p style={{textIndent: "40px"}}>
              A videók 1 perces szakaszokban láthatók. Egy-egy videó átnézése után tudjuk kiválasztani a kategóriát, majd a következő videódarabot kiválasztani vagy ugrani a jobb alsó
              nyíllal lehet.</p>
              <p style={{textIndent: "40px"}}>
              20 rövid videó van egy csomagban. Amikor elkészül mind a 20 videó tagelése, akkor jelenik meg a mentés gomb, amivel tárolható a jelölés egyben mind a 20 szakaszra, nem
              előbb! Ha megszakad a kapcsolat, a nyitott ablakon tovább lehet dolgozni, nem szükséges újrakezdeni.</p>
              <p style={{textIndent: "40px"}}>
              A videókban lehet navigálni, azonban 15 mp-es időszakot elvárunk egy-egy videóra a biztos kategória kiválasztására. Ekkor lehet a következőre ugrani.
              </p>
              <p><b><u>Az elszámolás menete:</u></b></p>
              <p style={{textIndent: "40px"}}>Eltöltött idő alapján. 1 perces videón tölthető idő: maximum 1 perc. Átpörgetni nem lehet csak egyértelműen sötét vagy üres inkubátoroknál,
              gyorsabban haladni a többinél lehet (max 4x). Mentett 20 videós csomagonkénti időt számolunk el.</p>
              <br/>
              <p><b>Köszönjük a segítséget!</b></p>
              <p><i>A Szervezők</i></p>
          </div>
          <div style={{ maxWidth: "300px", margin: "auto auto" }}>
            <button
              className="send"
              style={{ margin: "30px", maxWidth: "inherit", maxHeight: "inherit" }}
              onClick={() => handleClick("/")}
            >
              Értettem!
            </button>
            {isAdmin && <button
              className="send"
              style={{
                margin: "30px",
                maxWidth: "inherit",
                maxHeight: "inherit",
              }}
              onClick={() => handleClick("/admin")}
            >
              Felhasználói adatok
            </button>}
          </div>
        </div>
      <Footer />
      </div>
    </div>
  );
}

export default TaskDescription;
