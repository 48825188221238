import React, {useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import AnnotatedDataContext from '../../store/data-context';

export default function NewData(props) {

    const [error, setError] = useState("");
    const { logout } = useContext(AuthContext);
    const dataContext = useContext(AnnotatedDataContext);
    const navigate = useNavigate();

    function handleLoggingOut(){
        // Function to handle logout
        const confirmLogout = window.confirm("Biztos, hogy ki akarsz lépni? Az el nem küldött adatok nem mentődnek el!");
        if(confirmLogout){
            handleLogout();
        }
    }
    
    async function handleLogout(){
        setError("");

        try {
          await logout();
          dataContext.setMightLoadNewData(false);
          props.close(false);
          navigate("/login");
        } catch {
          setError("Failed to log out");
        }

    };
  
    const newVideos = async() => {        
        dataContext.receivedPreAnnotatedData();
        props.resetListValue(dataContext.annotatedData.video1);
        dataContext.setMightLoadNewData(false);
        props.close(false);
    };
  

  
    return (
      <>
        <dialog open className="dialog-container">
          <div className="dialog-content">
            <p className="dialog-message">Annotált videók beküldve! <br /> Új videókat töltenél be vagy inkább kilépnél?</p>
            <div className="dialog-buttons">
              <button onClick={newVideos} className="confirm-button">Új Videók</button>
              <button onClick={handleLoggingOut} className="cancel-button">Kijelentkezés</button>
            </div>
          </div>
        </dialog>
      </>
    );
  }
