import NavBar from "../NavBar/NavBar";
import { useEffect, useState } from "react";

function AdminPage(props) {
  const [userData, setUserData] = useState({});

  function convertData(data) {
    const convertedData = {};

    // Iterate through each user in the data
    Object.keys(data.thismonth).forEach((userKey) => {
        const userData = {
            username: data.thismonth[userKey].username,
            thisMonthSeconds: data.thismonth[userKey].seconds,
            lastMonthSeconds: data.lastmonth[userKey].seconds,
            totalSeconds: data.total[userKey].seconds,
        };
        
        // Assign the user data to the converted data object
        convertedData[userKey] = userData;
    });
    
    return convertedData;
}

useEffect(() => {
  const socket = new WebSocket(process.env.REACT_APP_WEBSPATH);

  // Sending a specific message to the server
  socket.onopen = () => {
    socket.send(";GET_Months"); // Sending a specific message to the server
  };

  socket.onmessage = (event) => {
    const socketmessage = JSON.parse(event.data);
    setUserData(convertData(socketmessage))
  }
    
  socket.onclose = (event) => {

  }

  // Cleanup function to remove event listener and close the socket connection when component unmounts
  return () => {
    socket.close();
  };
  }, []);

  function handleClickOnRefreshButton() {
    const socket = new WebSocket(process.env.REACT_APP_WEBSPATH);

    // Sending a specific message to the server
    socket.onopen = () => {
      socket.send(";GET_Months"); // Sending a specific message to the server
    };

    socket.onmessage = (event) => {
      const socketmessage = JSON.parse(event.data);
      setUserData(convertData(socketmessage))
    }
    
    socket.onclose = (event) => {
    }
  }

  function secsToMinutes(secs){
    const seconds = parseInt(secs);
    const minutes = Math.floor(seconds / 60);
    const leftSeconds = seconds % 60;

    const minsAndSecs = (minutes.toString() + " perc " + leftSeconds.toString() + " másodperc");

    return minsAndSecs;
  }

  return (
    <>
      <NavBar />
      <div>
        <button
          className="send"
          style={{ margin: "30px", maxWidth: "inherit", maxHeight: "inherit" }}
          onClick={handleClickOnRefreshButton}
        >
          Frissítés
        </button>
        <table className="medical-table">
          <thead>
            <tr>
              <th>
                <b>Felhasználó</b>
              </th>
              <th>
                <b>Havi Munkaidő</b>
              </th>
              <th>
                <b>Előző havi Munkaidő</b>
              </th>
              <th>
                <b>Teljes Munkaidő</b>
              </th>
            </tr>
          </thead>
          <tbody>
          {Object.keys(userData).map((key) => (
            <tr key={key}>
              <td>{userData[key].username}</td>
              <td>{secsToMinutes(userData[key].thisMonthSeconds)}</td>
              <td>{secsToMinutes(userData[key].lastMonthSeconds)}</td>
              <td>{secsToMinutes(userData[key].totalSeconds)}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AdminPage;
