export const twentyAnnotatedData = {
  video1: {
    id: 1,
    title: "Video 01",
    src: "",
    label: null,
    time: 0,
  },
  video2: {
    id: 2,
    title: "Video 02",
    src: "",
    label: null,
    time: 0,
  },
  video3: {
    id: 3,
    title: "Video 03",
    src: "",
    label: null,
    time: 0,
  },
  video4: {
    id: 4,
    title: "Video 04",
    src: "",
    label: null,
    time: 0,
  },
  video5: {
    id: 5,
    title: "Video 05",
    src: "",
    label: null,
    time: 0,
  },
  video6: {
    id: 6,
    title: "Video 06",
    src: "",
    label: null,
    time: 0,
  },
  video7: {
    id: 7,
    title: "Video 07",
    src: "",
    label: null,
    time: 0,
  },
  video8: {
    id: 8,
    title: "Video 08",
    src: "",
    label: null,
    time: 0,
  },
  video9: {
    id: 9,
    title: "Video 09",
    src: "",
    label: null,
    time: 0,
  },
  video10: {
    id: 10,
    title: "Video 10",
    src: "",
    label: null,
    time: 0,
  },
  video11: {
    id: 11,
    title: "Video 11",
    src: "",
    label: null,
    time: 0,
  },
  video12: {
    id: 12,
    title: "Video 12",
    src: "",
    label: null,
    time: 0,
  },
  video13: {
    id: 13,
    title: "Video 13",
    src: "",
    label: null,
    time: 0,
  },
  video14: {
    id: 14,
    title: "Video 14",
    src: "",
    label: null,
    time: 0,
  },
  video15: {
    id: 15,
    title: "Video 15",
    src: "",
    label: null,
    time: 0,
  },
  video16: {
    id: 16,
    title: "Video 16",
    src: "",
    label: null,
    time: 0,
  },
  video17: {
    id: 17,
    title: "Video 17",
    src: "",
    label: null,
    time: 0,
  },
  video18: {
    id: 18,
    title: "Video 18",
    src: "",
    label: null,
    time: 0,
  },
  video19: {
    id: 19,
    title: "Video 19",
    src: "",
    label: null,
    time: 0,
  },
  video20: {
    id: 20,
    title: "Video 20",
    src: "",
    label: null,
    time: 0,
  },
};
