import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { AuthContext } from "../../store/AuthContext";


const NavBar = () => {
  const [error, setError] = useState("");
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  function showDescription() {
    navigate("/task-description");
  }
  async function handleLogingOut(){
    const confirmLogout = window.confirm("Biztos, hogy ki akarsz lépni? Az el nem küldött adatok nem mentődnek el!");
    if(confirmLogout){
        handleLogout();
    }
  }
  async function handleLogout() {
    setError("");

    try {
      await logout();
      navigate("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  return (
    <>
      <nav>
        <span>Felhasználónév: {currentUser.email}</span>
        <span className="nav-link" onClick={showDescription}>
          Feladat Leírás
        </span>
        <span className="nav-link" onClick={handleLogingOut}>
          Kijelentkezés
        </span>
      </nav>
    </>

  );
};

export default NavBar;
