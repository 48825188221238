import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import React from "react";
import AnnotationPage from "./assets/AnnotationPage/AnnotationPage";
import LoginForm from "./assets/LoginForm/Login";
import TaskDescription from "./assets/TaskDescription/TaskDescription";
import AdminPage from "./assets/AdminPage/AdminPage";
import { AuthProvider } from "./store/AuthContext";
import PrivateRoute from "./assets/PrivateRoute/PrivateRoute";
import { AnnotatedDataContextProvider } from "./store/data-context";
import ProtectedRoute from "./assets/ProtectedRoute/ProtectedRoute";

function App() {
  return (
    <Router>
      <AnnotatedDataContextProvider>
        <AuthProvider>
          <Routes>
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <ProtectedRoute>
                  <AdminPage />
                  </ProtectedRoute>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                    <AnnotationPage />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/task-description"
              element={
                <PrivateRoute>
                  <TaskDescription />
                </PrivateRoute>
              }
            ></Route>
            <Route path={"/login"} element={<LoginForm />}></Route>
          </Routes>
        </AuthProvider>
      </AnnotatedDataContextProvider>
    </Router>
  );
}

export default App;
