import React, { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../store/AuthContext";
import AnnotatedDataContext from "../../store/data-context";

function LoginForm() {
  const { login } = useContext(AuthContext); // Destructure the signup function from context
  const dataContext = useContext(AnnotatedDataContext);
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(
        usernameInputRef.current.value,
        passwordInputRef.current.value
      ); // Call the login function with email and password
      dataContext.receivedPreAnnotatedData();
      navigate("/task-description");
    } catch (error) {
      setError("Failed to login: " + error.message);
    }

    setLoading(false); // Reset loading state after signup attempt
  }

  return (
    <form className="login-container">
      <div className="login-form">
        <input
          id="email-address"
          name="email"
          type="email"
          required
          placeholder="Felhasználónév"
          ref={usernameInputRef}
        />
        <br />
        <input
          id="password"
          name="password"
          type="password"
          required
          placeholder="Jelszó"
          ref={passwordInputRef}
        />
        <br />
        {error && (
          <p className="ErrorMessage">Invalid email address or password</p>
        )}
        <button disabled={loading} onClick={handleSubmit}>
          Login
        </button>
      </div>
    </form>
  );
}

export default LoginForm;
