import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import VideoList from "../VideoList/VideoList";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import NavBar from "../NavBar/NavBar";
import "../../App.css";
import BabyStateList from "../BabyStateList/BabyStateList";
import AnnotatedDataContext from "../../store/data-context";
import { AuthContext } from "../../store/AuthContext";
import NewData from "../NewData/NewData";
import Footer from "../Footer/Footer";

const AnnotationPage = () => {
  const dataContext = useContext(AnnotatedDataContext);
  const playerRef = useRef(null);
  const [listValue, setListValue] = useState(dataContext.annotatedData.video1);
  const [send, setSend] = useState(dataContext.checkIfListCompleted);
  const [isEnoughTime, setIsEnounghTime] = useState(true);
  const authContext = useContext(AuthContext);
  const [mayLoadNewData, setMayLoadNewData] = useState(dataContext.mightLoadNewData);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on("waiting", () => {
      //videojs.log("player is waiting");
    });

    player.on("dispose", () => {
      //videojs.log("player will dispose");
    });
  };

  const handleVideoSelect = (video) => {
    const player = playerRef.current;
    // Update the video source in the options

      const newOptions = {
        ...videoJsOptions,
        sources: [{ src: video.src, type: "video/mp4" }],
      };
  
      player.src(newOptions.sources);
      player.load();
      player.play();

  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    muted: true,

    playbackRates: [1.0, 2.0, 3.0, 4.0],
    sources: [
      {
        src: listValue.src, // Initial source will be set dynamically when a video is selected
        type: "video/mp4",
      },
    ],
  };

  useEffect(() => {
    setSend(dataContext.checkIfListCompleted);
  }, [dataContext.checkIfListCompleted, listValue]);

  const handleLeftOnClick = (videoId) => {
    // last video
    if (1 < videoId) {
      let enoughTime = dataContext.stopper(listValue);
      if(videoId !== listValue.id){
        setIsEnounghTime(enoughTime);
      }
      handleVideoSelect(dataContext.annotatedData[`video${videoId - 1}`]);
      setListValue(dataContext.annotatedData[`video${videoId - 1}`]);
    }
  };

  const handleRightOnClick = (videoId) => {
    // next video
    if (videoId < 20) {
      let enoughTime = dataContext.stopper(listValue);
      if(videoId !== listValue.id){
        setIsEnounghTime(enoughTime);
      }
      handleVideoSelect(dataContext.annotatedData[`video${videoId + 1}`]);
      setListValue(dataContext.annotatedData[`video${videoId + 1}`]);
    }
  };

  const handleSendOnClick = (username) => {
    // calls the function which sends data to backend
    if(!mayLoadNewData){
      dataContext.setMightLoadNewData(true);
      setMayLoadNewData(true);
      dataContext.stopper(listValue);
      dataContext.sendAnnotatedData(username);
    }else{
      // do noting
    }
  };

  useEffect(()=>{
    dataContext.stopper(listValue);

    if(dataContext.annotatedData.video1.src === ""){
      dataContext.receivedPreAnnotatedData();
      setListValue(dataContext.annotatedData.video1);
    }
  
  },[]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {

      // Cancel the event (prevents the page from refreshing)
      event.preventDefault();
      // Prompt the user with a custom confirmation message
      event.returnValue = ''; // Required for Chrome
      return ''; // Required for other browsers
    };

    // Add event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleVideo = useCallback((video) => {
    let enoughTime = dataContext.stopper(listValue);
    if(video.id !== listValue.id)
    setIsEnounghTime(enoughTime);
      handleVideoSelect(video);
      setListValue(dataContext.annotatedData[`video${video.id}`]);
  });

  const handleVideoLabel = useCallback((video, label) => {
    dataContext.fillAnnotatedData(video, label);
    setListValue((prevData) => ({
      ...prevData,
      label: label,
    }));
  });

  const handleLoadNewVideos = useCallback((video) =>
    {
      setListValue(video)
    }
  );

  return (
    <div className="page-container">
      <div className="content-wrap">
        <NavBar />      
        <div className="videocontainer">
          <VideoList videoData={listValue} callBack={handleVideo} />
          
          <div className="videoplayercontainer">
            <VideoPlayer
            options={videoJsOptions}
            onReady={handlePlayerReady}
            />
            <div className="navButtonContainer">
              <button className="navigation" onClick={() => handleLeftOnClick(listValue.id)} title="Előző videó">
                <img src="left-arrow.png" alt="Előző" style={{ width: '250%', height: '250%' }} />
              </button>
              {send && <button className="send" onClick={() => handleSendOnClick(authContext.currentUser.email)}>
                Küldés
              </button>}
              <button className="navigation" onClick={() => handleRightOnClick(listValue.id)} title="Következő videó">
                <img src="right-arrow.png" alt="Következő" style={{ width: '250%', height: '250%' }} />
              </button>
            </div>
          </div>
            <BabyStateList
              videoData={listValue}
              setVideoData={setListValue}
              callBack={handleVideoLabel}
            />
        </div>
        {mayLoadNewData && <NewData close={setMayLoadNewData} resetListValue={handleLoadNewVideos}/>}
      </div>
      <Footer />
    </div>
  );
};

export default AnnotationPage;
