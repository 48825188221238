import React from "react";

const babyStatesList = [
  "Csendes alvás",
  "Aktív alvás",
  "Átmeneti",
  "Csendes ébrenlét",
  "Aktív ébrenlét",
  "Sírás",
  "Nem jó a kép, túl sötét, zajos",
  "Nincs bent a baba",
  "Invazív kezelés",
  "Noninvazív kezelés",
  "Nem látszik teljesen",
  "Nem tudom eldönteni"
];

const babyStatesDesc = [
  "A csendes alvást a poliszomnográfos vizsgálatokban nem folytonos tevékenység jellemzi. A baba nagyon kevés mozgást végez, innen is jön a neve, annak ellenére, hogy alkalmanként felriadhat, vagy apró szájmozgásokat végezhet. A légzés lassabb és egyenletesebb, mint az aktív alvás során. ",
  "Az aktív alvást néha paradox alvásnak is nevezzük, mivel az EEG mintázatok nagyon hasonlítanak az ébrenléti állapothoz. Az aktív alvás alatt hirtelen gyors szemmozgásokat (Rapid Eye Movement) figyelhetünk meg a szemhéjak alatt és a poliszomnográfos mérések folyamatos elektromos aktivitást mutatnak az agyban. Szintén észrevehetünk változásokat az arckifejezésekben, beleértve felvillanó „mosolyokat”, rövid ideig nyitott szemeket, illetve a baba nyugtalanabbnak és idegesebbnek tűnhet. A légzés gyorsabb és szabálytalanabb, mint a csendes alvás alatt.",
  "Az ébredés és az elalvás általában fokozatosan végbemenő folyamat, miközben egy átmeneti, álmos időszakon haladunk végig. A koraszülöttek esetében az ébredés hirtelenebb lehet, illetve az alvás és ébredés között lebeghetnek anélkül, hogy teljesen felébrednének. Az átmeneti állapotok alatt a szemek lehetnek zárva, de az aktivitási szint azt jelzi, hogy a baba már nem alszik; ugyanakkor a szemek lehetnek akár nyitva vagy zárva is vagy találkozhatunk nyitott szemmel, amely üveges tekintettel párosul.",
  "Csendes ébrenlét alatt a baba szeme nyitva van, és nagyon keveset mozog. A légzés általában szabályos. Ezek az időszakok megfelelőek a babával való interakcióhoz, illetve hogy megfigyeljük a szülei érintésére és hangjára adott válaszait.",
  "A csecsemő aktivitási szintje azt jelzi, hogy nem alszik, bár a szeme lehet nyitva vagy zárva is. A légzés általában szabálytalan. Az 5. állapot-ban a baba jelezheti, hogy éhes vagy nem érzi magát kényelmesen. Néha arrais képes lehet, hogy megnyugtassa magát és visszajusson egy csendesebb állapotba, ha nem lesz egyre éberebb és zaklatottabb.",
  "A Sírás állapotban lévő baba egyértelműen ideges, zaklatott. Az időre született baba erőteljesen és teli torokból sírhat, míg a koraszülött csecsemőknél inkább rövid, gyenge sírást hallhatunk, illetve a nyugtalanságukat gyakran egy csendes sírással vagy izgatott tevékenységgel fejezhetik ki.",
];

const BabyStateList = (props) => {
  const handleLinkClick = (index) => {
    props.callBack(props.videoData, index);
  };

  return (
    <div>
      <h3>Választható címkék:</h3>
      <ul>
        {babyStatesList.map((item, index) => (
          <li key={index}>
            <a style={{fontSize: '18px'}}
              className={index === props.videoData.label ? "active" : ""}
              onClick={() => handleLinkClick(index)}
            >
              {item}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BabyStateList;
